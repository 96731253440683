import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <section className="section" style={{width: '80%'}}>
        <div className="container">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScFLTRzNjHWV4PBQ6DNzMuY0EE4v1BmWPCayPY0MH4mCE2y8w/viewform?embedded=true" width="100%" height="500" frameborder="1" marginheight="0" marginwidth="0">Loading…</iframe>
          {/* <p style={{maxWidth: '300px'}}>
            Due to overwhelming response, we are currently disabling our contact form for 24 hours till 26.01.2021 12:00 CET. Meanwhile please contact us directly at <strong>contact@berlincricketacademy.com</strong>.
          </p> */}
        </div>
      </section>
      // <section className="section" style={{width: '80%'}}>
      //   <div className="container">
      //     <div className="content">
      //       <form
      //         name="contact"
      //         method="post"
      //         action="/contact/thanks/"
      //         data-netlify="true"
      //         data-netlify-honeypot="bot-field"
      //         onSubmit={this.handleSubmit}
      //       >
      //         {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      //         <input type="hidden" name="form-name" value="contact" />
      //         <div hidden>
      //           <label>
      //             Don’t fill this out:{' '}
      //             <input name="bot-field" onChange={this.handleChange} />
      //           </label>
      //         </div>
      //         <div className="field">
      //           <label className="label" htmlFor={'name'}>
      //             Name
      //           </label>
      //           <div className="control">
      //             <input
      //               className="input"
      //               type={'text'}
      //               name={'name'}
      //               onChange={this.handleChange}
      //               id={'name'}
      //               required={true}
      //               placeholder="Name"
      //             />
      //           </div>
      //         </div>
      //         <div className="field">
      //           <label className="label" htmlFor={'email'}>
      //             Email
      //           </label>
      //           <div className="control">
      //             <input
      //               className="input"
      //               type={'email'}
      //               name={'email'}
      //               onChange={this.handleChange}
      //               id={'email'}
      //               required={true}
      //               placeholder="Email Address"
      //             />
      //           </div>
      //         </div>
      //         <div className="field">
      //           <label className="label" htmlFor={'message'}>
      //             Message
      //           </label>
      //           <div className="control">
      //             <textarea
      //               className="textarea"
      //               name={'message'}
      //               onChange={this.handleChange}
      //               id={'message'}
      //               required={true}
      //             />
      //           </div>
      //         </div>
      //         <div className="field">
      //           <button className="button is-rounded bca-button-primary" type="submit">
      //             Submit
      //           </button>
      //         </div>
      //       </form>
      //     </div>
      //   </div>
      // </section>
    );
  }
}
